import { Type } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { RoleJava } from './role';
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { DealViewabilityFilter } from '../../features/exchange-deals/exchange-deal-form/_services/exchange-deal-form.config';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RxFormDealSubmitActionId } from '../../features/exchange-deals/_services/exchange-deals.config';

export const featureToggleKeys = [
  'AudienceTargeting',
  'KeyValuePairTargeting',
  'BizDevOwnerDealPage',
  'DemandReportBuyingChannelFilter',
  'NetSuiteVendorUpdate',
  'PackageForecasting',
  'dealExternalSync',
  'PayOnWinCheckbox',
  'PaymentHistory',
  'SpearadLink',
  'consentCheckbox',
  'coppaCheckbox',
  'frequencyCapDaily',
  'MaskDemoData',
  'ExchangeDeals',
  'HelpCenter',
  'dealExchangeExternalSync',
  'pubDealPackageSov',
  'googleMarketplaceSync',
  'KvpConfig'
] as const;
export type FeatureToggleKeys = typeof featureToggleKeys;
export type FeatureToggles = {
  [K in FeatureToggleKeys[number]]?: boolean
};
export type FeatureTogglesKey = keyof FeatureToggles;


const sharedStoreAssetKeys = [
  'featureToggles',
  'optionsLists',
  'combinedSelectOptions',
  'dspAccounts',
  'countries',
  'opsOwners',
  'bizdevOwners',
  'timezones',
  'currencies',
  'trafficSources',
  'trafficSourcesByPublisherId',
] as const;
export type SharedStoreAssetKey = typeof sharedStoreAssetKeys[number];
export type SharedStoreAssetResponseStructureType<T> = ResponseFromServer<T | T[]> | T | T[];
export interface SharedStoreAssetConfig<T> {
  key: SharedStoreAssetKey;
  requestConfig: ApiServiceRequestConfig;
  responseIntegrityTest: (res: SharedStoreAssetResponseStructureType<T>) => boolean;
  isLoading: boolean;
  queueSubject: BehaviorSubject<Observable<SharedStoreAssetResponseStructureType<T>>>;
  response$: Observable<SharedStoreAssetResponseStructureType<T>>;
}

export interface ResponseFromServer<T> {
  data: T;
  paginated?: boolean;
  message?: string;
  status?: string;
  type?: string;
  totalElements?: number;
  isLoading?: boolean;
  pageSize?: number;
}

export class CommonDataModel<T = any> {
  data: T;
  status?: 'Fetching' | 'Failed' | 'Success';
  totalElements: number;
  isPaginated: boolean;
  isLoading: boolean;
  isPreparingForDownload?: boolean;
  httpErr: any;
  rxMetaData?: ResponseFromNestServerMeta;

  constructor(data: any = null,
    totalElements: number = 0,
    isPaginated: boolean = true,
    isLoading: boolean = false,
    isPreparingForDownload: boolean = false,
    httpErr: any = null) {

    this.data = data;
    this.totalElements = totalElements;
    this.isPaginated = isPaginated;
    this.isLoading = isLoading;
    this.isPreparingForDownload = isPreparingForDownload;
    this.httpErr = httpErr;
  }
}

export class AppResponseHandler<T> {
  response$: Observable<T>;
  isLoading$: Observable<boolean>;
  httpError$: Observable<any>;
  private responseSubject: BehaviorSubject<T>;
  private isLoadingSubject: BehaviorSubject<boolean>;
  private httpErrorSubject: BehaviorSubject<any>;

  constructor(response: any = null, isLoading: boolean = false, httpError: any = null) {

    this.responseSubject = new BehaviorSubject<T>(response);
    this.response$ = this.responseSubject.asObservable();
    this.isLoadingSubject = new BehaviorSubject<boolean>(isLoading);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.httpErrorSubject = new BehaviorSubject<any>(httpError);
    this.httpError$ = this.httpErrorSubject.asObservable();
  }

  onFetch(): void {
    this.isLoadingSubject.next(true);
    this.httpErrorSubject.next(null);
  }

  onSuccess(response: T): void {
    this.isLoadingSubject.next(false);
    this.responseSubject.next(response);
  }

  onError(error = throwError(new Error())): void {
    this.isLoadingSubject.next(false);
    this.httpErrorSubject.next(error);
  }

  start(obs$: Observable<T>): Observable<T> {
    this.onFetch();
    return obs$.pipe(
      catchError((err) => {
        this.onError(err);
        return of(null);
      }),
      tap((res: T) => {
        if (res) {
          this.onSuccess(res) ;
        }
      }),
    );

  }
}

export interface AuthPermissions {
  canRead?: boolean;
  canCreate?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
  showActions?: boolean;
}

export interface AuthPermissionsCRUD {
  read?: string;
  create?: string;
  update?: string;
  delete?: string;
}

export interface OperationsOwner {
  email: string;
  id: number;
  name: string;
  userName: string;
}

export class AppTable {
  id: string;
  pageIndex: number;
  pageSize: number;
  sortBy: string;
  sortDirection: string;
  hasPageSizeChanger?: boolean;
  isPaginated?: boolean;
  tableClass?: string;
  pageSizeOptions?: number[];
  expandRowComponent?: CustomComponentConfig;

  constructor(id: string,
    pageIndex: number = 1,
    pageSize: number = 10,
    sortBy: string = '',
    sortDirection: string = 'asc',
    hasPageSizeChanger: boolean = false,
    isPaginated: boolean = true,
    tableClass: string = '',
    pageSizeOptions: number[] = [10, 20, 30, 40],
    expandRowComponent = null) {

    this.id = id;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
    this.hasPageSizeChanger = hasPageSizeChanger;
    this.isPaginated = isPaginated;
    this.tableClass = tableClass;
    this.pageSizeOptions = pageSizeOptions;
    this.expandRowComponent = expandRowComponent;
  }
}

export interface CustomComponent {
  data: any;
}

export class CustomComponentConfig {
  constructor(
    public component: Type<any>,
    public data: any = {},
  ) {}
}

export interface ExpandableTableRow {
  isExpanded: boolean;
  isExpandDisabled: boolean;
  expandRowComponent?: CustomComponentConfig;
}

export interface AppTableColumn {
  id: string | number;
  prop: string;
  displayName: string;
  type: string;
  isSortEnabled?: boolean;
  sortingProp?: string;
  actionId?: string;
  actions?: AppTableAction[];
  moreTag?: any;
  tooltip?: string;
  canBeHidden?: boolean;
  isHidden?: boolean;
  isFrozenCol?: boolean;
  commentTag?: any;
  extraClass?: string;
  externalDisplayName?: string;
  maxWidth?: string;
  displayNameFormatter?: Function;
  valueFormatter?: Function;
  dataExtractionFn?: Function;
  data?: any;
  currency?: string;
  colWidth?: string;
  tags?: any[];
}

export interface AppTableAction {
  id: string | number;
  name: string;
  displayName: string;
  auth: string;
  desc?: string;
  icon?: string;
  class?: string;
  hrefLink?: string;
  hrefIdProp?: string;
}

export class AppSelections {
  selectedItems: any[];
  excludedItems: any[];
  isSelectAll: boolean;
  changedItems?: any[];

  constructor(selectedItems: any[],
    excludedItems: any[],
    isSelectAll: boolean,
    changedItems: any[] = null) {
    this.selectedItems = selectedItems;
    this.excludedItems = excludedItems;
    this.isSelectAll = isSelectAll;
    this.changedItems = changedItems;
  }
}

export interface Publisher {
  accounting_id: number | string;
  badv: any;
  battr: number[];
  bcat: any;
  btype: number[];
  coppa: boolean | any;
  created_at: string;
  deleted_at: string;
  publisher_id: number | string;
  publisher_name: string;
  signup_step: string;
  status: string;
  tld_badv: any;
  updated_at: string;
  permissions?: any;
}
export type PublisherAccountType = 'MANAGED' | 'SELF_SERVE';

export interface ThinPublisher {
  publisherId: string | number;
  publisherName: string;
  deletedAt: Date;
  status: string;
  publisherTrafficSources: PublisherTrafficSource[];
  publisherAccountType: PublisherAccountType;
  operationsOwner: OperationsOwner;

  disabled?: boolean;
}

export interface PublisherEdit {
  accountingId: number | string;
  adsTxtEmailing: boolean;
  badv: any;
  battr: any;
  bcat: any;
  btype: any;
  coppa: boolean;
  createdAt: string;
  ctvPreconsented: boolean;
  deletedAt: string;
  externalDomain: string;
  externalName: string;
  googlePubExId?: number;
  payment?: PublisherPayment;
  paymentHoldReason: string;
  permissions: Array<Permission>;
  pubInitiatedDealPlatformfee: number;
  publisherId: number;
  dealBidPriceType: string;
  publisherInfo: PublisherInfo;
  publisherName: string;
  publisherNetSuite?: any;
  publisherTermsOfServices: any;
  sellerTypes: Array<string>;

  publisherR1SellerId: string;

  bothR1SellerId: string;

  intermediaryR1SellerId: string;
  signupStep: 'NEW' | 'PENDING' | 'CONTENT_OK' | 'CONTENT_DECLINED' | 'PAYMENT_HOLD' | 'PAYMENT_READY' | 'PAYMENT_APPROVED';
  status: string;
  tldBadv: any;
  unrulyInitiatedDealPlatformfee: number;
  unrulyPlatformFee: number;
  updatedAt: string;
  financial_documents?: any;
  unrulySellerId?: string;
  publisherNetsuite?: any;
  dspAccountExcludeList?: (string | number)[];
}

export interface PublisherPayment {
  paymentHistory?: any;
  publisherFinancialDocument?: any;
  publisherPayment: any;
  publisherPaymentMethodAch: any;
  publisherPaymentMethodPayoneer: any;
  publisherPaymentMethodPaypal: any;
  publisherPaymentMethodWire: any;
  publisherPaymentTax?: any;
}

export interface PublisherInfo {
  adServerTech: PublisherAdServerTech;
  adServerTechNotes: string;
  address1: string;
  address2: string;
  bizdevOwner: PublisherOwner;
  city: string;
  cogsType: string;
  company: string;
  companyWebsite: string;
  contactEmail: string;
  contactName: string;
  country: string;
  createdAt: string;
  geoList: any;
  heardAboutFrom: any;
  integrationType: Array<string>;
  internalNotes: string;
  network: boolean;
  operationsOwner: PublisherOwner;
  opportunityVolume: number;
  phone: string | number;
  propertyNotes: any;
  province: string;
  pubOpsCountry: string;
  pubCatList: any;
  publisherAccountType: string;
  publisherId: number;
  publisherMediaType: Array<string>;
  signupNotes: any;
  state: string;
  thirdPartyIntegration: boolean;
  updatedAt: string;
  zipCode: any;
}

export interface PublisherAdServerTech {
  name: string;
  id: number;
  displayName: string;
  configParameters: any;
}

export interface PublisherOwner {
  id: number;
  userName: string;
  name: string;
  email: string;
}

export interface KpiWidget<T = any> {
  key: T;
  value: {
    current: number;
    previous: number;
  };
  labels: {
    title: string;
    previous: string;
  };
  unit?: {
    left?: string;
    right?: string;
  };
  isLoading: boolean;
  decimals: number;
  isPreviousHidden?: boolean;
  extraData?: any;
  aggregationFunction?: AggregationFunction;
  requestConfig?: ApiServiceRequestConfig;
  minWidth?: number;
}

export interface EmailTemplate {
  content: string;
  createdAt: string;
  id: number;
  name: string;
  subject: string;
  updatedAt: string;
  wildcards: string;
}

export interface InventoryPackage {
  packageId?: number;
  publisher?: {
    publisherName?: string;
    publisherId?: number;
  };
  publisherId?: number;
  deletedAt?: string;
  domainDealPackages: DomainBundle[];
  placements: number[];
  excludedPlacements: number[];
  selectAllPlacements: boolean;
  includeFuturePlacements: boolean;
  status: any;
  description: any;
  packageConfigType: PackageConfigType;
  notes: any;
  filterCountry: any;
  filterCountryList: any;
  filterZipCode: any;
  filterZipCodeList: any;
  filterDma: any;
  filterDmaList: any;
  filterRegion: any;
  filterRegionList: any;
  filterGenre: any;
  filterGenreList: any;
  filterContentRating: any;
  filterContentRatingList: any;
  filterContentChannel: ClusivityType;
  filterContentChannelList: string;
  filterContentNetwork: ClusivityType;
  filterContentNetworkList: string;
  filterContentLivestream: ContentLivestream;
  filterContentLanguage: any;
  filterContentLanguageList: any;
  filterAdsize: null;
  filterAdsizeList: any;
  filterVideosize: null;
  filterVideosizeList: any;
  filterImpType: any;
  filterDeviceType: any;
  filterSite: number | boolean;
  filterApp: number | boolean;
  filterPreroll: boolean;
  filterMidroll: boolean;
  filterPostroll: boolean;
  filterViewabilityPercent: any;
  filterCtrPercent: any;
  filterVcrPercent: any;
  debugEnd: any;
  publishers?: any;
  filterDomainBundle?: string;
  segmentGroups?: AudienceTargetingGroup[];
  maxDataFee?: number;
  numberOfUnsavedDomains: number;
  numberOfSavedDomains: number;
  kvpTargeting?: Array<KvpTargeting>;
}

export type DealConfigType = 'STANDARD' | 'SHARE_OF_VOICE' | 'BUNDLED';

export type PackageConfigType = 'STANDARD' | 'BVOD' | 'BVOD_FIRST_PARTY_DATA';

export interface Deal {
  dailyImpCap: any;
  revenueCap: any;
  dailyReqCap: any;
  dayParting: any;
  dealEnd: any;
  publisherDealPackageSovs: DealPackageSov[];
  // dealPackageId: any;
  dealStart: any;
  configType: DealConfigType;
  dealType: any;
  platformfeeType: any;
  adpodSlotTarget: string;
  deletedAt: any;
  description: any;
  externalDealId: any;
  bidfloorCurrency: string;
  notes: string;
  overallImpCap: number;
  frequencyCapDaily: number;
  paceReqs: any;
  pubDealId: any;
  status: any;
  updatedAt: any;
  buyers?: DealBuyer[];
  // dealPackageName?: string;
  publisherId?: number;
  iabCategoryParent?: any;
  auctionType?: string;
  isPublisherTargeted?: boolean;
  packagesLeftToSelect: boolean;
  bizdevOwner?: BizDevOwner;
  marketplaceMode: boolean;
  marketplaceDescription?: string;
  marketplaceLogoPath?: string;
  marketplaceRepresentedGroups?: UntypedFormGroup[];
  dealExternalSync?: DealExternalSync;
  // dealPackageDeletedAt?: string | number;
}

export interface UploadFileMarketplaceLogo {
  uiAction: 'upload' | 'remove' | 'updated';
  file: NzUploadXHRArgs;
}

export interface DealPackageSov {
  // dealPackage: InventoryPackage,
  thinPublisherDealPackage: InventoryPackage;
  sovPercent: number;
}

export interface ExchangeDeal {
  name: string;
  dealId?: number;
  description: string;
  status: 'active' | 'inactive' | 'forecast_only';
  dealStart: string;
  dealEnd: string;
  externalDealId: string;
  type: string;
  officeCountry: string;
  bidfloorCurrency: string;
  brandId: number | null;
  campaign: string;
  agencyId: number | null;
  dealPartnerId: number | null;
  paId: number | null;
  applyPaDiscount: boolean;
  padAgreement?: PadAgreement;
  accountManagers: number[];
  adOps: number[];
  salesPersons: number[];
  dealPersonnels?: Personnel[];
  kpiTargetDailyRate: number | null;
  kpiTargetMonthlyRev: number | null;
  kpiTargetViewability: number | null;
  kpiTargetVcr: number | null;
  dailyReqCap: number | null;
  dailyImpCap: number | null;
  overallImpCap: number | null;
  supplyMarginPctEnabled: boolean;
  supplyMarginPct: number | null;
  paceReqs: boolean;
  precedence: boolean;
  dspAccounts: DspAccount[];
  sspAccounts: ExchangeDealSsps[];
  viewabilityPercentage: number;
  deviceType: string[];
  geoTargeting?: ExchangeDealGeoTargeting;
  wseat: string;
  wseatArray: string[];
  priorityId: number | null;
  auctionType: string;
  bidfloor: number | null;
  dataFee: number | null;
  demandMargin: DemandMargin;
  inventoryType: string;
  filterSsp: string;
  filterSspIds: string[] | { id: number }[];
  ssps?: SupplySource[];
  sourceRatingTarget?: string | null;
  dvabsSegmentId: string;
  guaranteedVerification: string;
  impType: string;
  dealPubdealBundles?: PubDealBundle[] | number[];
  filterSite: boolean;
  filterApp: boolean;
  mediaType?: string[];
  requireContentTitle: boolean | 0 | 1;
  adType: string;
  filterVideoSkip: boolean | null | 0 | 1;
  filterVideoPlaybackMethod: number[];
  filterVideoMinContentLength: string | number | null;
  filterMinImpBidfloor: number | null;
  filterMaxImpBidfloor: number | null;
  filterVideoSizeList: string[];
  filterOmsdk: boolean;
  filterVpaid: boolean;
  playThrough: boolean;
  verticalVideo: boolean;
  filterPreroll: boolean;
  filterMidroll: boolean;
  filterPostroll: boolean;
  filterCountryList?: string | string[] | GeoTargetingFilterList[];
  filterCountrylist?: string | string[];
  filterRegionList?: string | string[];
  filterDmaList?: string | string[];
  filterZipCodeList?: string | string[];
  filterCountry: ClusivityType;
  filterRegion: ClusivityType;
  filterDma: ClusivityType;
  filterZipCode: ClusivityType;
  filterDeviceType: any;
  dealPixels: DealPixel[];
  notes: string;
  createdAt: string;
  updatedAt: string;
  filterAdsize: string | null;
  filterAdsizeList: string[];
  isIbv: boolean;
  filterImpSubType: string[];
  videoSizeList: string[] | null;
  impressionSubTypes: string[] | null;
  filterVideosize: string | null;
  filterSspDealIds: number[];
  sspDeals: SspDeals[];
  adSizeList: string[] | null;
  dealSegmentGroups?: AudienceTargetingGroup[];
  safetyFilters: { id: number }[];
  dealViewabilityFilters: DealViewabilityFilter[];
  filterCtrPercent: number;
  filterVcrPercent: number;
  filterContentLivestream: ContentLivestream;
  marketplaceMode?: boolean;
}

export interface DealPixel {
  dealId?: number;
  impressionUrl: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum NestAuditAction {
  Create = 'CREATED',
  Update = 'UPDATED',
  Delete = 'DELETED',
}

export interface NestAudit {
  id: number;
  timestamp: Date;
  author: string | number;
  entityId: string | number;
  entityName: string;
  action: NestAuditAction;
  oldValue: object | null;
  newValue: object | null;
}

export interface ExchangeDealAction {
  action: string;
  id: string;
  row: ExchangeDeal;
}

export interface ExchangeDealForecastResult extends ExpandableTableRow {
  forecastId: number;
  dealId: number;
  fusionUserId: number;
  fusionUserName: string;
  dealConfig: object;
  result: ExchangeDealForecastResultResult;
  createdAt: Date;
  updatedAt: Date;
  totalRequests?: ExchangeDealForecastResultTotals;
}

export interface ExchangeDealForecastResultTotals {
  available: number;
  unavailable: number;
  total: number;
}

export interface ExchangeDealForecastResultResult {
  externalDealId: string;
  fileCount?: number;
  lineCount?: number;
  dataDate: string;
  dspAccounts: Map<string, ExchangeDealForecastResultEntity>;
  countries: Map<string, ExchangeDealForecastResultEntity>;
  elapsed_time?: number;
  userWarnings: string[];
  errors: string[];
  status: string;
}

export interface ExchangeDealForecastResultEntity {
  requests: number;
  attached: ExchangeDealForecastResultEntityAttached;
  notAttached: {[key: string]: number};
  expectedRequests: number;
  expected_lines: number;
  samplerate: number;
}

export interface ExchangeDealForecastResultEntityAttached {
  count: number;
  existingImpressions: number;
  topDomains: ExchangeDealForecastResultEntityTopDomain[];
}

export interface ExchangeDealForecastResultEntityTopDomain {
  name: string;
  count: number;
}

export interface DealBuyer {
  buyerType: string; // [ DIRECT, PREFERRED, STANDARD ]
  defaultBidfloor: any;
  dspAccount: any;
  pubDeal: any;
  pubDealBuyerId: any;
  status: any;
  wadomain: any;
  wseat: any;
  deletedAt?: any;
  useImpBidfloor?: any;
}

export interface IdNumber {
  id: number;
}

export interface FilterSelectOption {
  displayName: string;
  id: string;
  value: string;
  isDisabled?: boolean;
}
export interface PossibleValueAppFilter {
  id: number;
  displayName: string;
  value: string;
}

export interface GroupAppFilter {
  groupId: number;
  groupName: string;
  groupDisplayName: string;
  possibleValues: PossibleValueAppFilter[];
}

export interface UpdateAppFiltersRenderer {
  filterId: string;
  selectOptionId: string;
  hideCondition: boolean;
}

export type SortOption = 'asc' | 'desc';
export type SortDirectionType = 'DESC' | 'ASC' | null;

export class AppFilter {
  id: string;
  type: 'SELECT' | 'DATES_RANGE' | 'SEARCH' | 'SELECT_WITH_SWITCH' | 'SELECT_WITH_SORT' | string;
  prop: string;
  title: string;
  placeholder: string;
  selectMode?: 'default' | 'multiple' | 'tags';
  allowClear: boolean;
  isServerSearch?: boolean;
  serverSearchProps?: any;
  possibleValues?: any[];
  selectedValues?: any;
  searchText?: string;
  excludedValues?: any;
  isSelectAll?: boolean;
  isRequired?: boolean;
  defaultValues?: any;
  errorTooltip?: any;
  isDisabled?: any;
  isLoading?: boolean;
  showValueInLabel?: boolean;
  isValid?: boolean;
  isHidden?: boolean;
  gridArea?: string;
  isForApi?: boolean;
  sendParamAs?: 'payload' | 'query';
  customPayloadFormatter?: Function;
  customPayloadPath?: string;
  tooltip?: string;
  isReady?: boolean;
  isTitleHidden?: boolean;
  sortType: SortOption;
  groups?: GroupAppFilter[];
  limitFutureDateRange: Date;
  limitPastDateRange: Date;

  constructor(id: string,
    type: string,
    prop: string,
    title: string,
    placeholder: string,
    selectMode: 'default' | 'multiple' | 'tags' = 'default',
    allowClear: boolean = true,
    isServerSearch: boolean = false,
    serverSearchProps: any = null,
    possibleValues: any[] = [],
    selectedValues: any = null,
    searchText: string = null,
    isSelectAll: boolean = false,
    excludedValues: any = null,
    isRequired: boolean = false,
    defaultValues: any = null,
    errorTooltip: string = '',
    isDisabled: boolean = false,
    isLoading: boolean = false,
    showValueInLabel: boolean = false,
    isValid: boolean = true,
    isHidden: boolean = false,
    gridArea: string = '',
    isForApi: boolean = true,
    sendParamsAs: 'payload' | 'query' = 'payload',
    customPayloadFormatter: Function = null,
    customPayloadPath: string = '',
    tooltip: string = null,
    isReady: boolean = true,
    isTitleHidden: boolean = false,
    sortType: SortOption = 'asc',
    groups: any[] = null,
    limitFutureDateRange = null,
    limitPastDateRange = null,
  ) {
    this.id = id;
    this.type = type;
    this.prop = prop;
    this.title = title;
    this.placeholder = placeholder;
    this.selectMode = selectMode;
    this.allowClear = allowClear;
    this.isServerSearch = isServerSearch;
    this.serverSearchProps = serverSearchProps;
    this.possibleValues = possibleValues;
    this.selectedValues = selectedValues;
    this.searchText = searchText;
    this.isSelectAll = isSelectAll;
    this.excludedValues = excludedValues;
    this.isRequired = isRequired;
    this.defaultValues = defaultValues;
    this.errorTooltip = errorTooltip;
    this.isDisabled = isDisabled;
    this.isLoading = isLoading;
    this.showValueInLabel = showValueInLabel;
    this.isValid = isValid;
    this.isHidden = isHidden;
    this.gridArea = gridArea;
    this.isForApi = isForApi;
    this.sendParamAs = sendParamsAs;
    this.customPayloadFormatter = customPayloadFormatter;
    this.customPayloadPath = customPayloadPath;
    this.tooltip = tooltip;
    this.isReady = isReady;
    this.isTitleHidden = isTitleHidden;
    if (this.type === 'SELECT_WITH_SORT') {
      this.sortType = sortType;
    }
    this.groups = groups;
    this.limitFutureDateRange = limitFutureDateRange;
    this.limitPastDateRange = limitPastDateRange;
  }
}

export class BillPayment {
  billPaymentId: number;
  vendorId: number;
  totalAmount: number;
  billPaymentDate: string;
  currency: string;
  bills: Bill[];
  period: string;
  voided: boolean;
}

export class Bill {
  amount: number;
  billId: number;
  billPaymentId: number;
  vendorId: number;
  billDate: string;
  voided: boolean;
  exchangeRate: number;
}

export class ModalConfig {
  constructor(type: string = 'basic') {
    this.type = type;
  }
  type: string;
}

export type WarningBoxType = 'basic' | 'extended';

export class WarningBoxConfig {
  type: WarningBoxType;
  title?: string;
  content?: string;
  extraData?: any;
  extraDataTitle?: string;

  constructor(type: WarningBoxType = 'basic', title?: string , content?: string, extraDataTitle?: string, extraData?: any) {
    this.type = type;
    this.title = title;
    this.content = content;
    this.extraDataTitle = extraDataTitle;
    this.extraData = extraData;
  }
}

export interface JapiTableFilter {
  fieldName: string;
  operation: 'EQUALS' | 'LIKE' | 'IN' | 'BETWEEN' | 'IS_NULL' | 'IS_NOT_NULL';
  value: number | string | boolean | any[];
}

export interface JapiQuery {
  filter?: {
    filters?: JapiTableFilter[];
    id?: number | string;
    withTrashed?: boolean;
  };
  paging?: {
    number: number;
    size: number;
  };
  sorting?: {
    sortDirection: SortDirectionType;
    sortingField: string;
  };
  include?: string[];
}

export type UserType = 'INTERNAL' | 'EXTERNAL' | 'API';
export type UserTypeLower = 'internal' | 'external';
export type DefaultUi = 'CTRL' | 'SPEARAD';

export interface KeycloakToken {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  refresh_expires_in: number;
  expiresAt: number;
}

export interface KeycloakTokenDecoded {
  userId: number;
  userType: UserType;
  preferred_username: string;
  publisherId: number;
  partnerId: number;
  userRoles: string[];
  realm_access: {
    roles: string[];
  };
}

export interface KeycloakInfo {
  id: number;
  // userId: number;
  preferred_username: string;
  given_name: string;
  family_name: string;
  name: string;
  // type: UserType;
  userType: UserType;
  publisherId: number;
  partnerId: number;
  currencyId: number;
  timezoneId: number;
  defaultUi: DefaultUi;
  email_verified: boolean;
}

export interface CtrlUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  emailVerifiedAt: string;
  password: string;
  type: UserType;
  // userType: UserType;
  partnerId?: number;
  publisherId?: number;
  timezone?: Timezone | number;
  currency?: Currency | number;
  defaultUi?: string;
  form?: UntypedFormGroup;
  toBeRemoved?: boolean;
  gatewayApiKey?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}

export interface CtrlAuthorities {
  permissions?: string[];
  roles?: string[];
}

export type CtrlUserAuthorities = CtrlUser & CtrlAuthorities;
export type CurrentUser = KeycloakToken & CtrlUserAuthorities;

export interface Permission {
  id: number;
  name: string;
  createdAt: string;
  description: string;
  updatedAt: string;
}

export interface UserWithRoles {
  user: CtrlUser;
  permissions: Permission[];
  roles: RoleJava[];
}

export interface ContractFile {
  contractFileId: number;
  contractFileName: string;
  contractFileCreatedAt: string;
  contractFileLoading: boolean;
}


export interface PublisherToEditModel {
  publisher: any;
}

export interface CampaignOverrideListItem {
  campaign: string;
  publisherId?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface ApiRequestDataBundle {
  queryParams?: {
    [key: string]: any;
  };
  payload?: any;
}

export interface Payment {
  address1?: string;
  address2?: string;
  city?: string;
  country?: string;
  createdAt?: string;
  dba?: any;
  legalCompanyName?: string;
  paymentMethodId?: number;
  paymentMethodType?: string;
  paymentTerms?: string;
  state?: string;
  updatedAt?: string;
  zipCode?: string;
  taxId?: number;
  vendor1099?: boolean;
  emailPaymentNotifications?: string;
  currency?: string;
}

export interface PaymentState {
  data: Payment | PaymentMethodAch | PaymentMethodPaypal | PaymentMethodWire | PaymentMethodPayoneer;
  isLoading: boolean;
  httpErr: string;
}

export interface PaymentMethodPaypal {
  paypal_email_enc: string;
}

export interface PaymentMethodAch {
  bank_name_enc: string;
  bank_aba_routing_enc: string;
  bank_account_beneficiary_enc: string;
  beneficiary_name_enc: string;
}

export interface PaymentMethodWire {
  bank_account_beneficiary_enc: string;
  swift_enc: string;
  iban_enc: string;
  intermediate_routing_enc: string;
  wire_bank_name_enc: string;
  wire_bank_account_number_enc: string;
}

export interface PaymentMethodPayoneer {
  payoneerEmailEnc: string;
}

export interface Timezone {
  abbreviation: string;
  alias: string;
  createdAt: string;
  description: string;
  gmtOffset: number;
  id: number;
  isSupported?: boolean;
  updatedAt: string;
  dropdownLabel?: string;
}

export interface Currency {
  abbreviation?: string;
  currencySymbol?: string;
  id: number;
  name?: string;
  isSupported?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface ExchangeRate {
  currency: string;
  rate: number;
}

export interface PlacementStatus {
  status: string;
}

export interface Placement {
  badv: any;
  badvList: string[];
  battr: any;
  bcat: any;
  btype: any;
  dspAccountExcludeList: any;
  cat: any;
  checksum: any;
  commissions: Commission[];
  coppa: boolean;
  createdAt: string;
  defaultAppName: any;
  defaultAppStoreUrl: any;
  defaultDomainBundle: any;
  deletedAt: string;
  domainChecksum: any;
  externalSourceId: any;
  filterDomainBundle: any;
  floors: Floor[];
  lda: boolean;
  mediaType: string;
  notes: string;
  placementId: number;
  placementName: string;
  pubTs: any;
  publishedAt: string;
  status: string;
  tldBadv: any;
  updatedAt: string;
  vpaidSupport: boolean;
  demandProfile: any;
  numberOfUnsavedDomains: number;
  numberOfSavedDomains: number;
  contentAttrRequestExclude: string | string[];
  contentAttrReportExclude: string | string[];
  cot?: string;
}

export type ImpType = 'BANNER' | 'VIDEO' | 'NATIVE' | 'AUDIO';

export interface Floor {
  bidFloor: number;
  createdAt?: string;
  floorAdjustment?: number;
  floorAdjustmentType?: 'PERCENT' | 'FIXED';
  id?: number;
  impType?: ImpType;
  placementId: number;
  publisherTrafficSourceId?: number;
  updatedAt?: string;
  country?: any;
  deviceType?: any;
  isEnabled?: boolean;
  isHidden?: boolean;
  supplyPlacementFloorId?: number;
  isGeoFloor?: boolean;
  curatedDealFloor?: number;
  internalDspFloor?: number;
  geoFloors?: any[];
}

export interface Commission {
  id: number;
  placementId: number;
  commissionType: string;
  commission: number;
  overridePubTsCommission?: boolean;
}

export interface CommissionToSubmit {
  isCommissionFormValid: boolean;
  placementCommissions: Commission[];
}

export interface DomainBundle {
  id: number;
  domainBundle: string;
  placementId?: number;
  packageId?: number;
  pubTsId?: number;
  includeSubDomains: boolean;
  createdAt?: string;
  updatedAt?: string;
  changes?: string;
}

export interface PublisherStatus {
  publisherId: number;
  status: string;
}

export interface DomainBundlePayload {
  updatedDomainBundles: DomainBundle[];
  removedDomainBundles: DomainBundle[];
  addedDomainBundles: DomainBundle[];
  removeAllDomains: boolean;
}

export class RxSegmentMapPayload {
  updatedSegmentMaps: string[] = [];
  removedSegmentMaps: string[] = [];
  addedSegmentMaps: string[] = [];
  removeAllSegmentMaps: boolean;
  segmentDescription: string;
  existSegmentDescription: string;

  constructor(existSegmentName: string) {
    this.segmentDescription = existSegmentName;
    this.existSegmentDescription = existSegmentName;
  }

  updateSegmentDescription(segmentDescription: string): void {
    this.segmentDescription = segmentDescription;
  }

  updateDomainBundlePayload(domainBundlePayload: DomainBundlePayload): void {
    this.updatedSegmentMaps = domainBundlePayload.updatedDomainBundles?.map(d => d.domainBundle);
    this.removedSegmentMaps = domainBundlePayload.removedDomainBundles?.map(d => d.domainBundle);
    this.addedSegmentMaps = domainBundlePayload.addedDomainBundles?.map(d => d.domainBundle);
    this.removeAllSegmentMaps = domainBundlePayload.removeAllDomains;
  }

  isChanged(): boolean {
    return this.segmentDescription !== this.existSegmentDescription || this.removeAllSegmentMaps ||
      this.updatedSegmentMaps.length > 0 || this.removedSegmentMaps.length > 0 || this.addedSegmentMaps.length > 0;
  }

  isNotChanged(): boolean {
    return !this.isChanged();
  }
}

export interface NetSuiteVendor {
  addressBook: {
    items: NetSuiteVendorAddressBookItem[];
  };
  companyName: string;
  email: string;
  entityId: string; // PH pubID
  id?: string;
  eid?: string;
  is1099eligible: boolean;
  isPerson?: boolean;
  custentity_aw_publisher_id: number; // PH pubID
  customForm?: number;
  subsidiary?: number;
  custentity_aw_taxid_sourced: number;
  custentity_2663_email_address_notif?: string;
  terms: number;
  custentity5?: string; // ops owner
  custentity6?: string; // bizdev owner
  currency?: number; // 1 = USD
  category?: number; // 5 ???
  custentity_aw_pref_pmt_method?: number;
  paymentMethodId?: number;
  custentity_ga_ven_ph_signup_email_id?: string;
  custentity_ga_ven_ph_signup_date?: string;
  custentity_ga_ven_ph_signup_terms?: string;
  custentity_aw_paymenthold?: boolean;
  custentity_ga_vendor_pay_hold_detail?: string;
}

export interface NetSuiteVendorAddressBookItem {
  label: string;
  addressId?: number;
  addressBookAddress: {
    // attention: string; // MR/MRS - not mandatory
    // addrphone: string;
    addr1: string;
    addr2?: string;
    addr3?: string;
    country: string;
    state: string;
    // city: string;
    zip: string;
    addressee: string;
  };
}

export interface TrafficSource {
  accountingId: number;
  allowTmaxOverride: boolean;
  clickMacro: any;
  createdAt: string;
  defaultPlacementId: number;
  deletedAt: string;
  displayName: string;
  rxSspName: string;
  shortName: string;
  status: string;
  strictRevshare: boolean;
  tmax: number;
  trafficSourceId: number;
  updatedAt: string;
  visible?: boolean;
  supportBvod: boolean;
}

export interface PublisherTrafficSourceCommission {
  commission: number;
  commissionType: 'FIXED' | 'REVSHARE';
  createdAt: string;
  id: number;
  placementId: number;
  publisherTrafficSourceId: number;
  updatedAt: string;
}

export interface PublisherTrafficSourceRegionLimit {
  pubTsId: number;
  regionIdentifier: string;
  regionName?: string;
  qpsLimit: number;
  qpsDefined: boolean;
}

export interface RegionLimitMetadata {
  pubTsId: number;
  regionsMaxQps: Map<string, number>;
}

export interface PublisherTrafficSource {
  commissions: PublisherTrafficSourceCommission[];
  createdAt?: string;
  defaultBundle: any;
  demandProfile: string;
  filterDomainBundle: string;
  trafficSourceId?: number;
  trafficSource?: TrafficSource;
  floors: Floor[];
  pubTsId: number;
  status: string;
  updatedAt?: string;
  visible: boolean;
  uploadedFile?: NzUploadFile[];
  domainBundlePayload?: DomainBundlePayload;
  numberOfUnsavedDomains: number;
  numberOfSavedDomains: number;
  market: string;
  sellerType: string;
  contentAttrRequestExclude: string | string[];
  contentAttrReportExclude: string | string[];
  trafficSourceName?: string;
  cot?: string;
  qpsLimitRegions: PublisherTrafficSourceRegionLimit[];
}

export interface DomainFilter {
  isDomainFilterValid: boolean;
  filterDomainBundle: string;
  domainsCount: number;
  isDomainsLoading: boolean;
}

export interface AudienceTargetingGroup {
  id: number;
  name: string;
  include: boolean;
  segments: AudienceTargetingSegment[];
  isOpen?: boolean;
}

export interface AudienceTargetingSegment {
  segmentId: number | undefined;
  sourceSegmentId?: number;
  segmentName: string;
  description?: string;
  providerName?: string;
  cpm?: number;
  publisherId?: number;
  status?: StatusActivity;
  dmpAudienceId?: number;
  type?: string;
  segmentTypeName?: string;
  segmentAudienceDisabled?: boolean;
  segmentMaps?: string[];
}

export class KvpTargeting {
  filterType: string;
  publisherKvp: PublisherKvp;
  possibleValues?: Array<PublisherKvpValue>;

  constructor(filterType: string, publisherKvp: PublisherKvp, possibleValues: Array<PublisherKvpValue> = []) {
    this.filterType = filterType;
    this.publisherKvp = publisherKvp;
    this.possibleValues = possibleValues;
  }
}

export class PublisherKvp {
  publisherKvpKey: PublisherKvpKey;
  publisherKvpValues: Array<PublisherKvpValue>;

  constructor(publisherKvpKey: PublisherKvpKey, publisherKvpValues: Array<PublisherKvpValue>) {
    this.publisherKvpKey = publisherKvpKey;
    this.publisherKvpValues = publisherKvpValues;
  }
}

export class PublisherKvpKey {
  id: number;
  kvpKey: string;
  displayName: string;
  updatedAt: string;
  createdAt: string;
  constructor(id: number = null, kvpKey: string = 'Key', displayName: string = null) {
    this.id = id;
    this.kvpKey = kvpKey;
    this.displayName = displayName;
  }
}

export class PublisherKvpValue {
  id: number;
  kvpKeyId: number;
  kvpValue: string;
  displayName: string;
  updatedAt: string;
  createdAt: string;
  deletedAt: string;
  constructor(kvpValue: string) {
    this.kvpValue = kvpValue;
  }
}

export class PublisherKvpValueResponse {
  publisherKvpValuesDtos: PublisherKvpValue[];
  createdValuesQty: number;
  existingValuesQty: number;
  invalidValuesQty: number;
  duplicateValuesQty: number;
  errMessage: string;
}

export interface ContentGenre {
  attribute: string;
  createdAt: string;
  id: number;
  updatedAt: string;
  value: string;
}

export enum SegmentType {
  mediadomain = 'Media Domain',
  mediaid = 'Media ID',
  pubid = 'Publisher ID'
}

export enum WindowMessageType {
  DATA_POINTS_REQUEST = 'DATA_POINTS_REQUEST',
  DATA_POINTS = 'DATA_POINTS',
  RESULT = 'RESULT',
  ACK = 'ACK',
  TEST = 'TEST'
}

export enum WindowMessageAckType {
  DATA_POINTS = 'DATA_POINTS',
  RESULT = 'RESULT',
  TEST_NEXT = 'TEST_NEXT'
}

export class GoogleCTASession {
  token: string;
  originProduct: string;
  googlePubId: string;
  redirectUrl: string;
  networkCode: string;
}

export enum GoogleCTATestDirective {
  START = 'START',
  NEXT = 'NEXT'
}

export enum GoogleCtaResultType {
  SUCCESS = 'SUCCESS',
  CANCEL = 'CANCEL',
  OTHER = 'OTHER',
  ERROR = 'ERROR'
}

export interface GoogleCtaResultOptions {
  'keep-window-open'?: boolean;
  'result-details'?: string;
}

export interface PopupExternal {
  id: string;
  localStorageKey: string;
  isVisible: boolean;
}

export interface KeyValueTitle<T = string> {
  title: string;
  value: string;
  key: T;
}

export interface KeyValueEnabled<> {
  title: string;
  value: string;
  enabled: boolean;
}

export interface KeyValuesType {
  id: number;
  type: string;
}

export interface KeyValue {
  id?: number;
  key?: string;
  value?: string;
}

export interface BizDevOwner {
  createdAt: string;
  deletedAt: string;
  email: string;
  id: number;
  name: string;
  status: string;
  updatedAt: string;
  userName: string;
}

export interface RejectReason {
  text: string;
  value: number | string;
  obj?: EmailTemplate;
}
export interface ApprovalQueueEmailDetails {
  customEmailText: string;
  templateComposerEnum: string;
  toSendEmail: boolean;
}

export type CtrlNavigationContext = 'ctrl' | 'spearad' | 'supply' | 'partner';

export interface CtrlNavigationEntry {
  id: string;
  label: string;
  path: string[];
  iconLayers?: FontAwesomeIconLayer[];
  lottieFile?: string;
  children?: CtrlNavigationEntry[];
  isSelected?: boolean;
  iframe?: boolean;
  publisherPermissions?: string[];
  rendererAuthConfig?: CtrlNavigationEntryRendererAuthConfig;
  rendererFn?: (obj: CtrlNavigationEntry, context: CtrlNavigationContext) => boolean;
  actionId?: string;
  position?: 'top'|'bottom';
  class?: string;
  tooltip?: string;
  disabled?: boolean;
  additionalBreadcrumbsConfigs?: {[key: string]: string};
  featureToggleKey?: FeatureTogglesKey;
}

export interface CtrlNavigationEntryRendererAuthConfig {
  userPermissions?: {
    permissions: string[];
    operand: 'AND'|'OR';
  };
  userRoles?: {[key: string]: boolean};
}

export interface Breadcrumb {
  label: string;
  path: string[];
  queryParams?: string;
}

export interface LastPath {
  ctrl?: string;
  spearad?: string;
}

export interface ISAMenuEntry {
  caption?: string;
  icon?: string;
  path: string;
  children?: ISAMenuEntry[];
  routerPath?: string[];
}
export interface PublisherType {
  id: string;
  value: string;
  label: string;
  // disabled - configuration for external user
  disabled?: boolean;
}
export interface Country {
  id: number;
  name: string;
  code: string;
  alpha2: string;
  alpha3: string;
  countryGroups?: any;
}

export interface State {
  regionKey: number;
  country: string;
  region: string;
  displayRegion: string;
}

export interface FontAwesomeIconLayer {
  icon: string;
  type?: 'fas'|'far';
  transform?: string;
  style?: string;
  spin?: boolean;
}

export interface AdServerTech {
  id: number;
  name: string;
  displayName: string;
}

export interface AssetsBlockedLists {
  iabCategories: any[];
  iabBannerAdTypes: IabBat[];
  iabCreativeAttributes: IabAttribute[];
  dspAccounts: DspAccount[];
}

export type ModalActionType = 'CANCEL' | 'DELETE' | 'APPROVE' | 'OK';

export interface ModalButton {
  type: ModalActionType;
  text: string;
  addClass?: string;
}

export interface BasicModal {
  id?: string;
  title?: string;
  buttons: ModalButton[];
}

export interface IabBat {
  adTypeId: number;
  description: string;
}

export interface IabAttribute {
  attributeId: number;
  description: string;
}

export interface DspAccount {
  id: number;
  shortName?: string;
  externalName?: string;
  nameDisplay?: string;
  name?: string;
  pubDealSupport?: boolean;
  exposeExternally?: number;
}

export interface Audit {
  timestamp: string;
  username: string;
  fieldName: string;
  parent: string;
  actionType: string;
  previousValue: any;
  newValue: any;
}

export interface Forecast extends ExpandableTableRow {
  forecastId: number;
  packageId: number;
  dspAccountName: string;
  result: ForecastResult;
  config?: ForecastConfig;
  createdAt: string;
}

export interface ForecastRunResponse {
  forecastId: number;
  result: ForecastResult;
}

export interface ForecastResult {
  errors: string[];
  status: 'success' | 'failed';
  dataDate: string;
  elapsedMS: number;
  fileCount: number;
  lineCount: number;
  userWarnings: string[];
  dspAccountName: string;
  realSampleRate: number;
  confidenceScore: number;
  availabilityRate: number;
  lowConfidenceWarning: number;
  package: ForecastResultPackage;
}

export interface ForecastResultPackage {
  requests: number;
  available: ForecastResultPackageAvailable;
  notAvailableReasons: ForecastResultPackageNotAvailableReasons;
}

export interface ForecastResultPackageAvailable {
  sampleCount: number;
  availableReqs: number;
  existingFillRate: number;
  existingImpressions: number;
  existingImpressionPubCost: number;
  existingImpressionPubCostAvg: number;
}

export interface ForecastResultPackageNotAvailableReasons {
  dma?: number;
  genre?: number;
  region?: number;
  country?: number;
  zip_code?: number;
  placement?: number;
  ctr_toolow?: number;
  vcr_toolow?: number;
  ctr_missing?: number;
  device_type?: number;
  vcr_missing?: number;
  domainbundle?: number;
  imp_sub_type?: number;
  contentrating?: number;
  content_network?: number;
  content_channel?: number;
  audience_exclude?: number;
  audience_include?: number;
  content_language?: number;
  viewability_toolow?: number;
  viewability_missing?: number;
  content_title_missing?: number;
}

export interface ForecastConfig {
  package: ForecastConfigPackage;
  dataDate: string;
  dspAccountName: string;
}

export interface ForecastConfigPackage {
  audienceTargetingGroups?: any[];
  contentLanguage?: ClusivityType;
  contentLanguageList?: string[];
  contentNetwork?: ClusivityType;
  contentrating?: ClusivityType;
  contentratingList?: string[];
  country?: ClusivityType;
  countryList?: string[];
  fullCountryList?: Country[];
  deviceTypeTarget?: string[];
  dma?: ClusivityType;
  dmaList?: string[];
  domainBundle?: string;
  domainBundleList?: string[];
  genre?: ClusivityType;
  genreList?: string[];
  impType?: string | ImpType; // TODO: remove "string" type
  packageID?: number;
  placementList?: string[];
  publisherID?: number;
  region?: ClusivityType;
  regionList?: string[];
  requireContentTitle?: number;
  timestamp?: number;
  vcrPercent?: number;
  viewabilityPercent?: number;
  zipCode?: ClusivityType;
  zipCodeList?: string[];
}

export type ClusivityType = 'include' | 'exclude';

export type ForecastFilterConfigType = 'table' | 'filter' | 'singleValue' | 'multiValue';

export type ForecastFilterConfigDataFormatter = (config: ForecastFilterConfig) => any;

export type ForecastFilterConfigValueFormatter = (val: any, config: ForecastFilterConfig) => any;

export interface ForecastFilterConfigTableColumn {
  key: string;
  label: string;
  cellFormatter?: ForecastFilterConfigValueFormatter;
}

export type ForecastFilterConfigTitleFormatter = (config: ForecastFilterConfig) => string;

export interface ForecastFilterConfig {
  type: ForecastFilterConfigType;
  dataKey: string;
  data: any;
  dataFormatter?: ForecastFilterConfigDataFormatter;
  clusivityKey?: string;
  clusivity?: ClusivityType;
  title?: string;
  titleFormatter?: ForecastFilterConfigTitleFormatter;
  assets?: any;
  assetsStoreSelector?: string;
  assetsServiceGetter?: string;
  assetsServiceGetterArgs?: string[];
  valueFormatter?: ForecastFilterConfigValueFormatter;
  tableColumns?: ForecastFilterConfigTableColumn[];
}

export type ModeType = 'edit' | 'read';

export enum ContentLivestream {
  LIVE = 'Live Stream',
  NOT_LIVE = 'VOD',
}

export interface DropdownButton {
  id: string | RxFormDealSubmitActionId;
  label: string;
  disabled?: boolean;
  extraClass?: string;
}

export interface MainDropdownButton extends DropdownButton {
  buttonType: 'primary-button' | 'secondary-button';
}

export interface DropdownButtonsConfig {
  main: MainDropdownButton;
  options: DropdownButton[];
}

export type SanitizerType = 'html' | 'style' | 'script' | 'url' | 'resourceUrl';

export interface AppChartResult {
  name: string;
  value: number;
}

export interface AppChartSeries {
  name: string;
  series: AppChartResult[];
  nameForChartTooltip?: string;
  isVisibleInLegend?: boolean;
  legendTooltipTitle?: string;
}

export type AppChartType = 'line' | 'barVertical' | 'barHorizontal' | 'pie' | 'gauge' | 'table';

export interface AppChartConfig<T = any> {
  key: T;
  type: AppChartType;
  data: AppChartResult[] | AppChartSeries[];
  isLoading: boolean;
  size?: AppChartSizeConfig;
  xAxis?: AppChartAxis;
  yAxis?: AppChartAxis;
  autoScale?: boolean;
  showLegend?: boolean;
  timeline?: boolean;
  title?: string;
  customColors?: {name: string; value: string; dashed?: boolean; hollow?: boolean}[];
  extraData?: any;
  extraClass?: string;
  requestConfig?: ApiServiceRequestConfig;
  requiredPermissions?: string[];
}

export interface AppChartSizeConfig {
  aspectRatio?: number;
  minHeight?: number;
  maxHeight?: number;
}

export interface AppChartAxis {
  showLabel?: boolean;
  label?: string;
  ticks?: string[] | number[];
  tickFormat?: AppChartTickFormat;
}

export type AppChartTickFormat = (val: any, config: AppChartConfig) => string;

export interface ApiServiceRequestConfig extends ApiRequestDataBundle {
  apiServiceMethod: string;
  methodArgumentPaths?: string[];
  methodArguments?: any[];
  preRequestHandler?: Function;
  responseHandler?: Function;
  expiresAt?: number;
}

export interface ContentNetwork {
  id: number;
  network: string;
  publisherId: number;
  networkDisplayName: string;
}

export interface ContentChannel {
  id: number;
  channel: number;
  publisherId: number;
  channelDisplayName: string;
}

export interface ExchangeDealPersonnel {
  data:  Personnel[];
}

export interface ExchangeDealDspAccounts {
  id: number;
  name?: string;
}

export interface ExchangeDealSsps {
  id: number;
  name: string;
}

export interface ExchangeDealGeoTargeting {
  countries?: string[];
  regions?: string[];
  metros?: string[];
  zipCodes?: string;
  filterCountry?: string;
  filterRegion?: string;
  filterDma?: string;
  filterZipCode?: string;
}

export interface GeoTargetingFilterList {
  nameProp: string;
  apiProp: string | number;
  queryProp: string | number;
}

export interface SspDealList {
  sspDealId: number;
  sspId: number;
  nameDisplay: string;
  externalDealId: string;
}

export interface SspDealsGroup {
  groupName: string;
  elements: SspDealList[];
}

export interface ExchangeDealsMeta {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
}


export interface ResponseFromNestServerMeta {
  totalItems?: number;
  itemCount?: number;
  itemsPerPage?: number;
  totalPages?: number;
  currentPage?: number;
}

export interface ResponseFromNestServerData<T> {
  items: T;
  meta?: ResponseFromNestServerMeta;
}


export interface ResponseFromNestServer<T> {
  data: ResponseFromNestServerData<T>;
}
export interface Personnel {
  dealPersonnelId?: number;
  id?: number;
  role?: string;
  name?: string;
}

export interface DeviceType {
  ANY: string;
  CONNECTEDTV: string;
  CTV: string;
  MOBILE: string;
  PHONE: string;
  TABLET: string;
  DESKTOP: string;
  SETTOPBOX: string;
}

export interface DealAgency {
  id: number;
  name: string;
}

export interface SupplySource {
  id: number;
  name?: string;
  nameDisplay?: string;
}

export interface ActiveInactiveSsps {
  activeSspList: SupplySource[];
  inActiveSspList: SupplySource[];
}

export interface Campaign {
  campaign: string;
}

export interface OptionsLists {
  [enumKey: string]: {[optionKey: string]: string};
}

export interface OptionsList {
  DeviceType: DeviceType;
}

export interface Metro {
  metroKey: number;
  country: string;
  regions: string;
  metroName: string;
  displayMetroName: string;
}

export interface ServerError {
  error: {
    error: string;
    ststusCode: number;
    message: string[];
  };
}

export interface SelectIcon {
  id?: number;
}

export interface DealBrand {
  id: number;
  name: string;
  createdAt?: string;
  updatedAt?: string;
}

export type GenericForm<T> = {
  [K in keyof T]?: FormControl<T[K] | null>;
};

export enum AgreementType {
  PMP = 'PMP',
  OMP = 'OMP'
}
export interface PadAgreementSeat {
  paId?: number;
  dspAccountId: number;
  seats: string[];
  createdAt?: string;
  updatedAt?: string;
}
export interface PadAgreementTier {
  id?: number;
  paId: number;
  spendThreshold?: number;
  discountPercent?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface PadAgreement {
  paId?: number;
  agreementName?: string;
  agreementType?: AgreementType;
  includePubdealSpend?: boolean;
  startDate?: string;
  endDate?: string;
  isExpired?: boolean;
  currentSpend?: number;
  currentTier?: number;
  notes?: string;
  padAgreementSeats: PadAgreementSeat[];
  padAgreementTiers: PadAgreementTier[];
  createdAt?: Date;
  updatedAt?: Date;
}

export class DealType {
  id: number;
  type: string;
}

export class DemandMargin {
  demandMarginPct: number;
  demandMarginsByPublisher?: DemandMarginByPublisher[];
}

export class DemandMarginByPublisher {
  id: number;
  publisherId: number;
  publisherName: string;
  demandMarginPct: number;
}

export interface SafetyFilterProvider {
  id: number;
  provider: string;
  name: string;
  scoreThreshold?: number;
}

export interface DealPartner {
  id: number;
  name: string;
  displayName: string;
  status: StatusActivity;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}


export interface CombinedSelectOptions {
  dspAccounts: {activeDspAccountList: DspAccount[]; inActiveDspAccountList: DspAccount[] };
  dealAgencies?: DealAgency[];
  dealBrands?: DealBrand[];
  supplySources?:  {activeSspList: SupplySource[]; inActiveSspList: SupplySource[] };
  dealPersonnel?: Personnel[];
  dealTypes?: DealType[];
  unrulyBundledDeals: UnrulyBundledDeal[];
  currentServerDate: number;
  sspDealList?: SspDealsGroup[];
  safetyFilters: SafetyFilterProvider[];
  dealPartners?: DealPartner[];
}

export interface CachedResponse<T> {
  isFromCache: boolean;
  response: T;
}

export type AggregationFunction = 'SUM' | 'AVERAGE';

type DealExternalSyncActionType = 'SYNC' | 'RETRIEVE';
export type SyncType = 'buyer' | 'marketplace';

export interface DealExternalSync {
  id: number;
  pubDealId: number;
  dspAccountId: number;
  fullName: string;
  synced: boolean;
  syncType?: SyncType;
  syncResult: string;
  externalApiData: {
    id?: string;
    orderId?: string;
    productId?: string;
    dealStatus?: string;
    logoPathS3?: string;
    dv3MarketplaceUri?: string;
    logoUrl?: string;
  };
  dealStatus: { orderStatus: string };
  action: DealExternalSyncActionType;
  updatedAt: string;
  createdAt: string;
}


export interface UnrulyBundledDeal {
  pubDealId: number;
  externalDealId: string;
  description: string;
}

export interface PubDealBundle {
  pubDealId: number;
}


export interface SspDeals {
  id: number;
}

export interface SentinelResponse<T> {
  data: T;
}

export interface SentinelTemplateValue {
  values: SentinelValues;
  handler?: any;
}

export interface SentinelValues {
  email: string;
  publishers?: (string | number)[];
  publisherId?: (string | number);
  publisherName?: string;
  days?: number;
}

export interface SentinelSubscription {
  templateValue: SentinelTemplateValue;
  ruleId: number;
  userId: string;
}

export interface SentinelSubscriptionResponse {
  id: number;
  rules: SentinelRule;
  templateValue: SentinelTemplateValue;
  userId: string;
  createdAt: Date;
}

export interface SentinelRule {
  id: number;
  name: string;
  interval: string;
  description: string;
  notificationName: string;
  groupName: string;
}

export type ClusivityStatus = 'ACTIVE' | 'INACTIVE';
export type StatusActivity = 'active' | 'inactive';

export type PadTypeOptions = 'OMP' | 'PMP';

export type PortalType = 'supply' | 'portal' | 'partner';

export interface ControlContentAttribute {
  displayName: string;
  value: string;
  isDisabledForRequest: boolean;
  isDisabledForReport: boolean;
}

export interface AudienceTargetingQuery {
  keyword: string;
  provider: string;
  sort: string;
  page?: number;
  size?: number;
}

export interface PublisherListItem {
  publisherId: string;
  publisherName: string;
  disabled?: boolean;
}

export interface PublisherMedia {
  segmentIds: number[];
  page?: number;
  limit?: number;
}

export type PollingConfigResponseEvaluatorOperation = 'EQUALS'|'IS_NOT';

export interface PollingConfig {
  id: string;
  apiMethod: string;
  args?: any[];
  subscription?: Subscription;
  responseEvaluators: {
    success: {
      path: string;
      operation: PollingConfigResponseEvaluatorOperation;
      compareTo: any;
    };
    error: {
      path: string;
      operation: PollingConfigResponseEvaluatorOperation;
      compareTo: any;
    };
  };
  completionNotification: {
    success: {
      title: string;
      body: string;
    };
    error: {
      title: string;
      body: string;
    };
    notificationIdsToRemove?: string[];
  };
  completionEvent?: {
    name: string;
    data?: any;
  };
  interval?: number;
}

export interface CloneExchangeDeal {
  valid: boolean;
  externalDealId?: ExchangeDeal['externalDealId'];
  dealPartnerId?: ExchangeDeal['dealPartnerId'];
  dealEnd?: ExchangeDeal['dealEnd'];
  dealStart?: ExchangeDeal['dealStart'];
}
